<template>
  <app-modal-transaction-detail
    :show="showModalDetail"
    @close="showModalDetail = false"
    :transaction="selectTransaction"
    :showBusiness="true"
    @update:transaction="selectTransaction = $event"
    routeName="support.balance.transaction.payment-link"
    masterBusinessSettlementRouteName="support.balance.transaction.master-business-payment-link"
  />
  <!-- Download CSV Modal -->
  <app-modal
    :show="show_modal_download_csv"
    @close="show_modal_download_csv = false"
  >
    <template v-slot:title>
      {{ $t("support.download_csv_title") }}
    </template>
    <template v-slot:body>
      <p class="text-sm text-gray-500">
        {{ $t("support.download_csv_description") }}
      </p>
    </template>
    <template v-slot:action>
      <app-button
        @click="downloadCsvTransaction"
        :showf70Icon="false"
        type="button"
        class="sm:ml-3 sm:w-auto"
      >
        {{ $t("general.download_csv") }}
      </app-button>
    </template>
  </app-modal>
  <app-table
    :apiResponse="apiPaginationResponse?.data ?? []"
    :loading="apiPaginationLoading"
    @params-changed="paramsChanged"
    :showEmptyButton="false"
    :filters="[
      {
        key: 'filter[business_name]',
        type: 'text',
        placeholder: $t('payout.table.account.header_business_name'),
      },
      {
        key: 'filter[amount]',
        type: 'text',
        placeholder: $t('payout.table.account.header_amount'),
      },
      {
        key: 'filter[type]',
        type: 'select',
        placeholder: 'Type',
        items: [
          {
            id: TRANSACTION_TYPE.TRANSFER_IN,
            name: $t('wallet.transaction.type_transfer_in'),
          },
          {
            id: TRANSACTION_TYPE.TRANSFER_OUT,
            name: $t('wallet.transaction.type_transfer_out'),
          },
          {
            id: TRANSACTION_TYPE.SETTLED_IN,
            name: $t('wallet.transaction.settled_in'),
          },
          {
            id: TRANSACTION_TYPE.SETTLED_OUT,
            name: $t('wallet.transaction.settled_out'),
          },
          {
            id: TRANSACTION_TYPE.PENDING,
            name: $t('wallet.transaction.pending'),
          },
        ],
      },
      {
        key: 'filter[created_at_between]',
        type: 'date',
        placeholder: $t('wallet.transaction.date'),
        value: '',
      },
    ]"
    @on-item-click="onItemClick"
  >
    <template v-slot:button>
      <app-button
        @click="show_modal_download_csv = true"
        :showf70Icon="false"
        type="button"
        class="sm:ml-3 sm:w-auto"
      >
        {{ $t("payout.modal.download_csv_button") }}
      </app-button>
    </template>
    <template v-slot:header>
      <th class="font-bold">{{ $t("wallet.transaction.date") }}</th>
      <th class="font-bold">{{ $t("wallet.transaction.business_name") }}</th>
      <th class="font-bold">{{ $t("wallet.transaction.description") }}</th>
      <th class="font-bold">{{ $t("wallet.transaction.type") }}</th>
      <th class="font-bold">{{ $t("wallet.transaction.amount") }}</th>
    </template>
    <template v-slot:body="slotData">
      <td>
        {{ $moment(slotData.model.created_at).format("DD MMM YYYY hh:mm a") }}
      </td>
      <td class="">
        {{ slotData.model.business?.name ?? "-" }}
      </td>
      <td>
        <p class="capitalize">
          {{ slotData.model.description }}
        </p>
      </td>
      <td>
        <p class="capitalize">
          {{ slotData.model.type?.replace("_", " ") }}
        </p>
      </td>
      <td class="flex place-items-center space-x-2 justify-between">
        <p
          class="font-medium"
          :class="{
            'text-success': slotData.model.type?.includes('_in'),
            'text-error': slotData.model.type?.includes('_out'),
          }"
        >
          {{
            $formats.currency(
              slotData.model.currency,
              slotData.model.amount ?? 0.0
            )
          }}
        </p>
      </td>
    </template>
  </app-table>
</template>
<script>

import TRANSACTION_TYPE from "@/utils/const/transaction_type";

export default {
  data() {
    return {
      showModalDetail: false,
      selectTransaction: null,
      TRANSACTION_TYPE: TRANSACTION_TYPE,
      show_modal_download_csv: false,
    };
  },

  mounted() {
    this.$store.dispatch("walletStore/getTransactionForSupport");
  },

  computed: {
    apiPaginationResponse() {
      return this.$store.getters["walletStore/apiPaginationResponse"];
    },

    apiPaginationLoading() {
      return this.$store.getters["walletStore/apiPaginationLoading"];
    },
  },

  methods: {
    paramsChanged(filters, queryParams) {
      this.$store.dispatch(
        "walletStore/getTransactionForSupport",
        queryParams
      );
    },

    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectTransaction = data;
    },

    async downloadCsvTransaction() {
      this.show_modal_download_csv = false;
      this.submitLoading = true;
      const csv_url = await this.$store.dispatch(
        "walletStore/getSupportTransactionExports",
        this.queryParams
      );

      this.submitLoading = false;

      if (csv_url.data) {
        window.location.href = csv_url.data;
      }
    },
  },
};
</script>